@import '../../index.scss';
.Homepage{
    @include flex(column, center, null);
    @media screen and (min-width: 780px) {
        @include flex(row, center, center);
    }
    .ContainerHomepage{
        @include flex(column, null, center);
        @media screen and (min-width: 780px) {
            @include flex(row, null, center);
        }
        .ContainerPicture{
            @include flex(column, center, null);
            margin-bottom: 30px;
            @media screen and (min-width: 780px) {
                @include flex(null, null, null);
                flex: 1;
                margin-right: 15px;
                margin-bottom: 0;
            }
            @media screen and (min-width: 835px) {
                max-width: 330px;
                margin-right: 60px;
            }

            h1{
                text-align: center;
                margin-top: 15px;
            }

            img{
                &.Picture{
                    width: 150px;
                    height: 150px;
                    margin-top: 15px;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 5px solid $main-color;
                    @media screen and (min-width: 780px) {
                        width: 225px;
                        height: 225px;
                        margin-top: 0;
                    }
                    @media screen and (min-width: 1110px) {
                        width: 100%;
                        height: 330px;
                    }
                }
            }

            .Logos{
                @include flex(null, center, center);
                img{
                    width: 33px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
        .ContainerText{
            padding: 0 8px 0 8px;
            @media screen and (min-width: 415px) {
                padding: 0;
            }
            @media screen and (min-width: 780px) {
                flex: 2;
                padding: 0 30px 30px 30px;
            }
            .ContainerAbout{
                margin-bottom: 40px;
            }
        }
        .ContainerContact{
            @include flex(null, center, space-around);
            margin-top: 25px;
            margin-bottom: 40px;
            height: 80px;
            background-color: rgba($main-color, .3);
            @include radius(25px);
            @media screen and (min-width: 780px) {
                margin-bottom: 0;
            }
            img{
                width: 33px;
            }
        }
    }
}