@import '../../index.scss';
.Trainings{
    .ContainerTrainings{
        @include flex(null, center, center);
        margin-top: 40px;
        margin-bottom: 80px;
        @media screen and (min-width: 780px) {
            margin-top: 80px;
        }
        .Container{
            .Graduates {
                .ContentTrainings {
                    margin-top: 50px;
                    .GroupTrainings {
                        @include flex(column, null, null);
                        &:not(:last-child) {
                            margin-bottom: 30px;
                        }
                        @media screen and (min-width: 1024px) {
                            display: grid;
                            grid-template-columns: 10% 20% 70%;
                            &:not(:last-child) {
                                margin-bottom: 0;
                                grid-template-rows: 100px;
                            }
                        }

                        .YearAndLocation {
                            @media screen and (min-width: 1024px) {
                                border-right: 1px solid $lighter-grey;
                                margin-right: 20px;
                            }
                            h3 {
                                margin-bottom: 0;
                                font-size: 25px;
                            }
                            h4 {
                                margin-top: 0;
                                opacity: .5;
                            }
                        }
                    }
                }
                &.Card {
                    > div{
                        &.ContainerGrid{
                            width: 80%;
                            margin: 0 auto;
                            > div{
                                width: 250px;
                            }
                        }
                    }
                    .BulletImg{
                        background-color: $green;
                    }
                }
            }
        }
    }
}