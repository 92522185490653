@import '../../index.scss';
.Skills{
    .ContainerSkills{
        @include flex(null, center, center);
        margin-top: 40px;
        margin-bottom: 80px;
        @media screen and (min-width: 780px) {
            margin-top: 80px;
        }
        .Container{
            > div{
                &:not(:last-of-type){
                    @media screen and (min-width: 400px) {
                        margin-bottom: 30px;
                    }
                }
            }
            .Dev{
                &.Card{
                    > div{
                        &.ContainerGrid{
                            > div{
                                @include flex(column, null, null);
                                width: 100%;
                                @media screen and (min-width: 400px) {
                                    width: 225px;
                                }
                            }
                        }
                    }
                    .SGBD{
                        @include flex(null, center, null);
                    }
                    .Front, .KnownFront, .KnownBack{
                        @include flex(null, center, null);
                        flex-wrap: wrap;
                        margin-top: 15px;
                        img{
                            margin-bottom: 10px;
                            &:not(:last-child){
                                margin-right: 4px;
                            }
                        }
                    }
                    h2{
                        color: $yellow;
                    }
                    h3{
                        &:not(:first-of-type){
                            padding-top: 30px;
                        }
                    }
                    p{
                        font-size: 13px;
                        margin: 5px 0;
                    }
                    .BulletImg{
                        background-color: $yellow;
                    }
                }
            }
            .ContainerCards{
                @include flex(column, null, space-between);
                @media screen and (min-width: 400px) {
                    margin-top: 50px;
                }
                @media screen and (min-width: 768px) {
                    @include flex(row, null, space-between);
                }
                .Card{
                    position: relative;
                    &.Language{
                        height: 250px;
                        h2{
                            color: $light-blue;
                        }
                        .BulletImg{
                            background-color: $light-blue;
                        }
                        .LanguageItem {
                            @include flex(null, center, null);
                        }
                    }
                    &.Design{
                        height: 250px;
                        img{
                            &:not(:last-of-type){
                                margin-right: 10px;
                            }
                        }
                        h2{
                            color: $blue;
                        }
                        .BulletImg{
                            background-color: $blue;
                        }
                    }
                }
            }
        }
    }
}