@import '../../index.scss';
.Navbar{
    @include flex(null, center, center);
    height: 60px;
    padding: 15px;
    background-color: $main-color;
    .Container{
        @include flex(null, null, null);
        .Name{
            flex: 1;
            font-family: 'MasterOfBreak', sans-serif;
            font-size: 25px;
            color: white;
            letter-spacing: 3px;
        }
        .Links{
            @include flex(null, center, space-between);
            margin: 0 50px;
            flex: 2;

            a{
                position: relative;
                color: white;
                font-size: 13px;
                font-weight: 900;
                letter-spacing: 1px;
                text-transform: uppercase;

                &::after{
                    position: absolute;
                    bottom: -7px;
                    left: 50%;
                    content: '';
                    width: 0;
                    height: 2px;
                    display: block;
                    background: white;
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    -ms-transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
                }
                &:hover{
                    &:after{
                        width: 100%; 
                        left: 0;
                    }
                }
            }
        }
    }
}