// --- VARIABLES --- //
$main-color: #6a5e6e;
$dark-main-color: #363038;
$light-grey: #eaeaea;
$lighter-grey: #b2bec3;
$darker-grey: #2d3436;
$yellow: #f9ca24;
$orange: #fa983a;
$blue: #2e86de;
$light-blue: #7ed6df;
$deep_purple: #30336b;
$purple: #3e4289;
$purple-light: #575cc2;
$purple-lighter: #878cf7;
$green: #6ab04c;

// --- MIXINS --- //
@mixin flex($direction, $align, $justify) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
}
@mixin radius($value...) {
    border-radius: $value;
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    -ms-border-radius: $value;
    -o-border-radius: $value;
}
@mixin filter($type) {
    filter: $type();
    -webkit-filter: $type();
}
@mixin transition($type, $value) {
    transition: $type $value;
    -webkit-transition: $type $value;
    -moz-transition: $type $value;
    -ms-transition: $type $value;
    -o-transition: $type $value;
}
@mixin transform($type) {
    transform: $type();
    -webkit-transform: $type();
    -moz-transform: $type();
    -ms-transform: $type();
    -o-transform: $type();
}

// --- FONTS --- //
@font-face {
    font-family: 'MasterOfBreak';
    src: url(./fonts/master_of_break.ttf);
}

// --- GENERAL --- //
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    height: 100vh;
    margin: 0;
}
body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $darker-grey;
    height: 100%;
}
#root{
    @include flex(column, null, space-between);
    height: 100%;
}
code{
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a{
    text-decoration: none;
    color: unset;
}
p{
    line-height: 1.5;
}
h1, h2{
    margin-bottom: 20px;
}
h1{
    font-weight: 900;
    font-size: 25px;
    @media screen and (min-width: 820px) {
        font-size: 32px
    }
}
h2{
    position: relative;
    padding-bottom: 7px;
    font-size: 25px;
    font-weight: 300;
    @media screen and (min-width: 820px) {
        font-size: 30px
    }
    &::after{
        content:"";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 0.2px solid $lighter-grey;
        z-index: -1;
    }
}
h3{
    margin-bottom: 10px;
}
h4{
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: normal;
}

.Container{
    width: 310px;
    @include flex(null, null, center);
    @media screen and (min-width: 325px) {
        width: 350px;
    }
    @media screen and (min-width: 370px) {
        width: 360px;
    }
    @media screen and (min-width: 410px) {
        width: 400px;
    }
    @media screen and (min-width: 768px) {
        width: 755px;
    }
    @media screen and (min-width: 810px) {
        width: 795px;
    }
    @media screen and (min-width: 830px) {
        width: 820px;
    }
    @media screen and (min-width: 1015px) {
        width: 1000px;
    }
    @media screen and (min-width: 1110px) {
        width: 1110px;
    }
}
.Card{
    position: relative;
    width: 100%;
    margin-top: 70px;
    padding: 60px 15px;
    background: #fff;
    box-shadow: 6px 3px 19px 0 rgba(56, 64, 86, 0.2);
    @include radius(25px);
    @media screen and (min-width: 410px) {
        padding: 60px 35px;
    }
    &:not(:last-of-type){
        margin-right: 20px;
    }
    > div{
        &.ContainerGrid{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: minmax(100px, auto);
            grid-gap: 10px;
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
            }
            > div{
                margin-top: 30px;
            }
        }
    }
}
.block{
    display: block;
    margin: 5px 0;
}

.ContainerType{
    @include flex(column, null, center);

    h1{
        text-align: center;
    }
}

.BulletImg{
    position: absolute;
    top: 0;
    left: 50%;
    @include flex(null, center, center);
    width: 85px;
    height: 85px;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 6px 3px 19px 0 rgba(56, 64, 86, 0.2);
    @include radius(50%);
    img{
        width: 45px;
    }
}

.ImgExtraSmall{
    width: 25px;
}
.ImgSmall{
    width: 30px;
}
.ImgMediumSmall{
    width: 40px;
}
.ImgMedium{
    width: 50px;
}
.ImgMediumLarge{
    width: 60px;
}
.ImgLarge{
    width: 75px;
}
.ImgExtraLarge{
    width: 100px;
}

.ButtonCustom{
    padding: 13px 16px;
    border: solid transparent;
    @include radius(16px);
    border-width: 0 0 4px;
    background-color: $dark-main-color;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    vertical-align: middle;
    outline: none;
    overflow: visible;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter .2s;
    user-select: none;
    -webkit-user-select: none;
    &:after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: -4px;
        z-index: -1;
        background-clip: padding-box;
        background-color: $main-color;
        border: solid transparent;
        @include radius(16px);
        border-width: 0 0 4px;
    }
    &:focus{
        user-select: auto;
    }
    &:hover:not(:disabled) {
        @include filter(brightness(1.1));
    }
    &:disabled {
        cursor: auto;
    }
    &:active {
        border-width: 4px 0 0;
        background: none;
    }

    @media screen and (min-width: 780px) {
        font-size: 18px;
    }
}