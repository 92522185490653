@import '../../../index.scss';

$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

.Mobile{
    position: relative;
    width: 100%;
    background-color: $main-color;

    #toggle {
        display: none; //hides the checkbox
        &:checked{
            & ~ .toggle-container .button-toggle {
                &:before {
                    transform: translateY(-50%) rotate(45deg) scale(1);
                }
                &:after {
                    transform: translateY(-50%) rotate(-45deg) scale(1);
                }
            }

            // Open Nav
            & ~ .nav {
                margin-bottom: 100px;
                pointer-events: auto;
                transform: translate(50px, 50px);
                // Restoring nav items from 'lines' in the menu icon
                .nav-item {
                    color: $lighter-grey;
                    letter-spacing: 0;
                    height: 40px;
                    font-size: 18px;
                    line-height: 40px;
                    margin-top: 0;
                    opacity: 1;
                    transform: scaleY(1);
                    transition: $transition-duration, opacity 0.1s;

                    // Setting delays for the nav items in open transition
                    @for $i from 1 through $items {
                        &:nth-child(#{$i}) {
                            $delay: ($items - $i) * $transition-delay;
                            transition-delay: $delay;
                            &:before {
                                transition-delay: $delay;
                            }
                        }
                    }

                    // Hide the lines
                    &:before {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .button-toggle {
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 25px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 100%;
        transition: $transition-duration + 0.1;

        // Making the 'X' icon using `:before` and `:after` pseudo-elements
        // Initially hidden because of `scale(0)` transformation

        &:before, &:after {
          position: absolute;
          content: '';
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $lighter-grey;
          border-radius: 5px;
          transition: $transition-duration;
        }
        &:before {
          transform: translateY(-50%) rotate(45deg) scale(0);
        }
        &:after {
          transform: translateY(50%) rotate(-45deg) scale(0);
        }
    }

    .nav {
        display: inline-block;
        margin: 25px 25px 20px;
        pointer-events: none;
        transition: $transition-duration;
    }

    // Showing nav items as lines, making up the hamburger menu icon
    .nav-item {
        display: inline-block;
        float: left;
        clear: both;
        color: transparent;
        font-size: 14px;
        letter-spacing: -6.2px;
        height: 7px;
        line-height: 7px;
        text-transform: uppercase;
        white-space: nowrap;
        transform: scaleY(0.2);
        transition: $transition-duration, opacity 1s;

        //Set delays for the nav items in close transition
        @for $i from 1 through $items {
            &:nth-child(#{$i}) {
                $delay: ($i - 1) * $transition-delay;
                transition-delay: $delay;
                &:before {
                    transition-delay: $delay;
                }
            }
        }

        // Adjusting width for first line
        &:nth-child(1) {
            letter-spacing: -4px;
        }

        // Adjusting width for second line
        &:nth-child(2) {
            letter-spacing: -7px;
        }

        // Adjusting the fourth element onwards
        &:nth-child(n + 4) {
            letter-spacing: -8px;
            margin-top: -7px;
            opacity: 0;
        }

        //getting lines for the hamburger menu icon
        &:before {
            position: absolute;
            content: '';
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $lighter-grey;
            transform: translateY(-50%) scaleY(5);
            transition: $transition-duration;
        }
    }
}