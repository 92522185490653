@import '../../index.scss';
.Experiences{
    .ContainerExperiences{
        @include flex(null, center, center);
        margin-top: 40px;
        margin-bottom: 80px;
        @media screen and (min-width: 780px) {
            margin-top: 80px;
        }
        .Container{
            .ContainerButtons{
                button{
                    margin-bottom: 12px;
                    &:first-of-type{
                        margin-right: 10px;
                    }
                    &:last-of-type{
                        margin-bottom: 45px;
                    }
                    @media screen and (min-width: 780px) {
                        margin-bottom: 45px;
                        &:last-of-type{
                            margin-bottom: 45px;
                        }
                    }
                }

            }

            .Dev{
                .BulletImg{
                    background-color: $deep_purple;
                }

                h2{
                    color: $deep_purple;
                }

                .ContainerDevFullstack{
                    .ContainerProjectName{
                        margin-top: 15px;
                    }
                }

                .ContainerDevReact,
                .ContainerDevFullstack,
                .ContainerDevBack{
                    h3{
                        margin-bottom: 15px;
                    }
                    h4{
                        text-transform: uppercase;
                        opacity: .5;
                        font-weight: 700;
                    }
                    p{
                        margin-bottom: 5px;
                        &:first-of-type{
                            margin-bottom: 15px;
                        }
                        &.SmallText{
                            font-size: 13px;
                        }
                        &.ProjectDesc{
                            color: grey;
                            font-style: italic;
                        }
                    }
                    .ContainerProjectName{
                        @include flex(null, center, null);
                        margin-bottom: 15px;
                        h3{
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        a{
                            width: 20px;
                            height: 20px;
                            text-decoration: underline;
                            @include transition(all, 0.4s);
                            &:hover{
                                @include transform(scale(1.2));
                            }
                            img{
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                            }
                            border-bottom: 1px solid black;
                            @media screen and (min-width: 1025px) {
                                border-bottom: none;
                            }
                        }
                    }
                    > div{
                        margin-bottom: 50px;
                    }
                    .Particeep{
                        margin-bottom: 40px;
                    }
                    .Dydu{
                        margin-bottom: 30px;
                    }
                }

                .ProjectsPerso,
                .Integration,
                .Dydu,
                .Particeep{
                    > div{
                        width: 60%;
                        @media screen and (min-width: 768px) {
                            width: initial;
                        }
                    }
                    @include flex(null, null, center);
                    flex-wrap: wrap;
                    @media screen and (min-width: 780px) {
                        @include flex(null, null, flex-start);
                    }
                    img{
                        width: 100%;
                        height: auto;
                        padding: 1px;
                        border: 1px solid $light-grey;
                        @include radius(3px);
                        @media screen and (min-width: 768px) {
                            width: auto;
                            height: 155px;
                            margin-right: 8px;
                        }
                    }
                }
                &.DevTop{
                    .BulletImg{
                        background-color: white;
                        img{
                            width: 70px;
                        }
                    }
                    .ContainerAllTops{
                        @include flex(null, null, null);
                        flex-wrap: wrap;
                        justify-content: center;
                        @media screen and (min-width: 768px) {
                            justify-content: initial;
                        }
                        .TopContainer{
                            position: relative;
                            width: 215px;
                            height: 165px;
                            margin-bottom: 30px;
                            border: 1px solid $light-grey;
                            @include radius(0 0 15px 15px);
                            @media screen and (min-width: 768px) {
                                &:not(:last-of-type){
                                    margin-right: 20px;
                                }
                            }
                            .TopContainerImg{
                                width: 100%;
                                height: 112px;
                                @include radius(15px 15px 0 0);
                                    border-bottom: 1px solid $light-grey;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .TopContainerName{
                                text-align: center;
                                padding: 7px 0;
                                font-weight: bold;
                                font-size: 20px;
                            }
                            .Bookmark{
                                position: absolute;
                                right: 12px;
                                img{
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                        }
                    }
                }
                &.DevFront{
                    .BulletImg{
                        background-color: $purple;
                    }
                    h2{
                        color: $purple;
                    }
                }
                &.DevBack{
                    .BulletImg{
                        background-color: $purple-light;
                    }
                    h2{
                        color: $purple-light;
                    }
                }
                &.DevFullstack{
                    .BulletImg{
                        background-color: $purple-lighter;
                    }
                    h2{
                        color: $purple-lighter;
                    }
                }
            }
            .Communication{
                .BulletImg{
                    background-color: $orange;
                }

                img{
                    width: 40px;
                    &:not(:last-of-type){
                        margin-right: 10px;
                    }
                }

                h2{
                    color: $orange;
                }

                .Creations{
                    @include flex(null, null, center);
                    flex-wrap: wrap;
                    @media screen and (min-width: 780px) {
                        @include flex(null, null, space-between);
                    }
                    img{
                        width: auto;
                        height: 200px;
                        margin-right: 10px;
                        @media screen and (min-width: 780px) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}